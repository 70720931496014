@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700&display=swap");
@import "_variables";

// Mixins
@mixin too-small-mobile {
  @media (min-width: $too-small-mobile) {
    @content;
  }
}
@mixin mid-small-mobile {
  @media (min-width: $mid-small-mobile) {
    @content;
  }
}

@mixin small-mobile {
  @media (min-width: $small-mobile) {
    @content;
  }
}

@mixin mid-mobile {
  @media (min-width: $mid-mobile) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: $mobile) {
    @content;
  }
}

@mixin small-tab {
  @media (min-width: $small-tab) {
    @content;
  }
}

@mixin big-tab {
  @media (min-width: $big-tab) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: $small-desktop) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin mid-desktop {
  @media (min-width: $mid-desktop) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: $large-desktop) {
    @content;
  }
}

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin background($position, $attachment) {
  background-position: $position;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: $attachment;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $font-color;
}

html {
  font-size: 50.5%;
  @include small-tab() {
    font-size: 55.5%;
  }
  @include big-tab() {
    font-size: 62.5%;
  }
}

body {
  scroll-behavior: smooth;
  font-family: "Assistant", sans-serif;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, $primary-color, $secondary-color);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &.fixed {
    overflow-y: hidden;
  }
}

main {
  min-height: 87vh;
  @include mobile() {
    min-height: 82vh;
  }
  &.grey {
    background-color: #efefef;
    @include center();
  }
}

a {
  text-decoration: none;
}

small {
  font-size: 1.2rem;
}

input[type="checkbox"] {
  accent-color: $primary-color;
}

.pagination {
  background-color: #fff;
  width: max-content;
  margin: auto;
  box-shadow: 2px 8px 40px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin-top: 2rem;

  .page-item {
    margin: 7px 5px;
    @include mobile() {
      margin: 7px 7px;
    }

    &.active .page-link {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white-color;
    }
    .page-link {
      color: $font-color;
      border: none;
      border-radius: 6px;
      font-size: 1.6rem;
      cursor: pointer;
      @include mobile() {
        padding: 0.4rem 0.86rem;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

// Keyframes
.wave {
  -webkit-animation-name: wave;
  animation-name: wave;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes wave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0);
  }
}

@keyframes wave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0);
  }
}

.playWave {
  -webkit-animation-name: playWave;
  animation-name: playWave;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  &.white {
    -webkit-animation-name: playWhiteWave;
    animation-name: playWhiteWave;
  }
}
@-webkit-keyframes playWave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 87, 20, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 87, 20, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
    box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
  }
}

@keyframes playWave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 87, 20, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 87, 20, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
    box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
  }
}

@-webkit-keyframes playWhiteWave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 87, 20, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 87, 20, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
    box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
  }
}

@keyframes playWhiteWave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(230, 229, 229, 0.8);
    box-shadow: 0 0 0px 0px rgba(189, 189, 189, 0.8);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
    box-shadow: 0 0 0px 10px rgba(255, 87, 20, 0);
  }
}

// Global
p {
  font-size: 2rem;
  @include small-tab() {
    font-size: 1.6rem;
  }
}
section {
  padding: 4.5rem 0;
  @include small-tab() {
    padding: 6rem 0;
  }
  &.contact {
    padding: 2rem 0;
    @include small-tab() {
      padding: 4rem 0;
    }
  }
}
.custom-btn {
  border: 0;
  outline: 0;
  background-color: $primary-color;
  width: 20rem;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  border-radius: 2.5rem;
  transition: 0.4s;
  transform: scale(1);
  border: 2px solid $primary-color;
  column-gap: 2px;
  &:hover {
    border: 2px solid $primary-color;
    background-color: $white-color;
    span {
      color: $primary-color;
    }
    circle {
      fill: $primary-color;
      transition: 0.4s;
    }
    path {
      fill: $white-color;
    }
  }
  @include small-tab() {
    height: 5rem;
  }
  span {
    width: 80%;
    color: $white-color;
    font-size: 1.8rem;
    transition: 0.4s;
    @include small-tab() {
      font-size: 1.8rem;
    }
  }
  img,
  svg {
    width: 16%;
    circle {
      fill: $white-color;
    }
    path {
      fill: $primary-color;
    }
  }
  &.invert {
    background-color: $white-color;
    border: 2px solid $primary-color;
    span {
      color: $font-color;
      font-weight: 600;
      transition: 0.4s;
    }
    svg {
      circle {
        transition: 0.4s;
        fill: $primary-color;
      }
      path {
        fill: $white-color;
      }
    }
    &:hover {
      background-color: $primary-color;
      span {
        color: $white-color;
      }
      svg {
        circle {
          fill: $white-color;
        }
        path {
          fill: $primary-color;
        }
      }
    }
  }
}
.highlight {
  &.primary {
    color: $primary-color;
  }
  &.secondary {
    color: $secondary-color;
  }
  &._bold {
    font-weight: 700;
  }
}

.secondary {
  color: $secondary-color !important;
}

span.small-tag {
  font-size: 1.8rem;
  display: inline-block;
  @include small-tab() {
    font-size: 1.6rem;
  }
}

.heading {
  padding-top: 2rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    height: 0.4rem;
    background-color: $primary-color;
  }
  &.line-secondary {
    &::before {
      background-color: $secondary-color;
    }
  }
  span.tag {
    font-size: 1.9rem;
    display: inline-block;
    @include small-tab() {
      font-size: 2rem;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 3.5rem;
    @include small-tab() {
      font-size: 4rem;
    }
    &.medium-size-header {
      font-size: 3.5rem;
    }
    &.huge-text {
      font-size: 14rem;
      line-height: 10rem;
      @include mobile() {
        font-size: 18rem;
        line-height: 14rem;
      }
      font-weight: 700;
      @include desktop() {
        font-size: 21rem;
        line-height: 18rem;
      }
    }
  }

  p {
    font-size: 2rem;
  }
  &.front-line {
    padding-left: 4rem;
    padding-top: 0;
    &::before {
      width: 0.4rem;
      height: 90%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.text-center {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h3 {
    font-size: 2.2rem;
  }
}
h3 {
  &.small-heading {
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 1.4rem;
    text-transform: capitalize;
    &.secondary {
      color: $secondary-color;
    }
    &.light {
      font-weight: 600;
    }
  }
  &.big-heading {
    font-size: 3.5rem;
    font-weight: 600;
  }
}

.bg-blob {
  position: relative;
  z-index: 2;
  background-color: #fef8ea91;
  &::before {
    content: "";
    position: absolute;
    width: 8rem;
    height: 8rem;
    top: 25%;
    left: 0;
    border-radius: 50%;
    box-shadow: 0 0 100px 10px #f3ad1bbf;
    background-color: #f3ad1b3b;
    pointer-events: none;
    z-index: 0;
  }
  &::after {
    content: "";
    position: absolute;
    width: 8rem;
    height: 8rem;
    bottom: 25%;
    right: 0;
    border-radius: 50%;
    box-shadow: 0 0 100px 10px #f3ad1bbf;
    background-color: #f3ad1b3b;
    pointer-events: none;
    z-index: 0;
  }
  ._blob {
    pointer-events: none;
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    box-shadow: 0 0 100px 10px #f3ad1bbf;
    background-color: #f3ad1b3b;
    z-index: 1;
    &.ls {
      top: 45%;
      left: 40%;
      transform: translateX(-50%);
      z-index: -1;
    }
    &.rs {
      z-index: -1;
      bottom: 50%;
      right: 30%;
      transform: translateX(-50%);
    }
  }
}
.product_card {
  margin-top: 6rem;
  background-color: $white-color;
  padding: 1rem;
  border-radius: 1.8rem;
  transition: 0.4s;
  border: 2px solid $white-color;
  max-width: 90%;
  transform: translateY(0rem);
  position: relative;
  z-index: 2;
  .head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    input {
      display: none;
      &[type="checkbox"]:checked + label::before {
        left: unset;
        right: 0;
      }
    }
    label {
      width: 3.5rem;
      height: 2rem;
      background-color: rgba($color: $grey, $alpha: 0.4);
      border-radius: 2.5rem;
      position: relative;
      transition: 0.4s;
      cursor: pointer;
      &::before {
        position: absolute;
        content: "";
        width: 2rem;
        height: 2rem;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: unset;
        background-color: $grey;
        z-index: 1;
        border-radius: 50%;
        pointer-events: none;
      }
    }
    span {
      font-size: 1.6rem;
      font-weight: 500;
      &:first-child {
        margin-right: 0.8rem;
      }
      &:last-child {
        margin-left: 0.6rem;
      }
    }
  }
  .product-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba($color: $grey, $alpha: 0.6);
    figure {
      width: 100%;
      height: 22rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1.8rem;
      }
    }
  }
  .product-details {
    padding: 1rem 0;
    a {
      color: $font-color;
    }
    h4 {
      text-align: center;
      font-size: 1.8rem;
      font-weight: 600;
      a {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    & > ul {
      padding: 1.5rem 0;
      border-bottom: 1px solid rgba($color: $grey, $alpha: 0.6);
      & > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.7rem;
        span {
          color: $secondary-color;
          font-size: 1.6rem;
        }
      }
    }
  }
  .card-foot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  &:hover {
    border: 2px solid $primary-color;
    transform: translateY(-1rem);
    .custom-btn {
      background-color: $primary-color;
      span {
        color: $white-color;
      }
      svg {
        circle {
          fill: $white-color;
        }
        path {
          fill: $primary-color;
        }
      }
    }
  }
  &._shadow {
    box-shadow: 0 0 20px #ae82223b;
    max-width: unset;
    margin-top: 1.5rem;
  }
}
.machine-bg {
  background-image: url("/assets/images/homepage/cta/robo.svg");
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("/assets/images/homepage/cta/robo.png");
    z-index: -1;
  }
}
// Main classes
header {
  background-color: $white-color;
  z-index: 5;
  position: relative;
  .cart-backdrop {
    height: 100vh;
    width: 100%;
    background-color: transparent;
    z-index: 4;
    left: 0;
    overflow-y: hidden;
    position: fixed;
    pointer-events: none;
    &.active {
      pointer-events: all;
      background-color: #29292944;
    }
  }
  nav {
    .top-nav-wrap {
      background-color: $grey;
      .top-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-connect {
          ul {
            display: flex;
            align-items: center;
            li {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 1.5rem;
              &:first-child {
                margin-right: 0.85rem;
                @include mid-small-mobile() {
                  margin-right: 2.5rem;
                }
                position: relative;
                bottom: -0.2rem;
              }
              a {
                color: $white-color;
                text-decoration: none;
                font-size: 1.5rem;
                img {
                  width: 1.5rem;
                 height: 1.5rem;
                }
              }
            }
          }
        }
        .right-connect {
          display: flex;
          .font-size-adjust-wrap {
            height: 100%;
            display: flex;
            justify-content: space-between;
            border-left: 1px solid $white-color;
            border-right: 1px solid $white-color;
            transition: 0.4s ease-in-out;
            width: 15rem;
            &:hover {
              background-color: $primary-color;
            }
            button {
              color: $white-color;
              background-color: transparent;
              width: 25%;
              font-size: 3.1rem;
              border: 0;
              outline: 0;
              padding: 0.2rem 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              font-size: 1.8rem;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50%;
              color: $white-color;
              margin-top: 0.2rem;
            }
          }
          .search-wrap {
            position: relative;
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              font-size: 1.8rem;
              border: 0;
              outline: 0;
              background-color: transparent;
              color: $white-color;
              transition: 0.4s ease-in-out;
              border-right: 1px solid $white-color;
              width: 5rem;
              @include mobile() {
                width: 9rem;
              }
              @include small-tab() {
                width: 11rem;
                border-right: unset;
              }
              &:hover {
                background-color: $primary-color;
              }
              img {
                width: 2.1rem;
                margin-bottom: 0.2rem;
                @include mobile() {
                  margin-right: 0.6rem;
                }
              }
              span {
                display: none;
                color: $white-color;
                @include mobile() {
                  display: inline-block;
                }
              }
            }
            .search-box {
              position: fixed;
              height: min-content;
              width: 100vw;
              border-radius: 0;
              left: 50%;
              top: 0;
              z-index: 1;
              @include big-tab() {
                position: absolute;
                left: 50%;
                width: 25rem;
                top: unset;
                border-radius: 2.5rem;
              }
              transform: translateX(-50%);
              bottom: -7rem;
              background-color: #ffe0a0;
              padding: 0.4rem 1rem;
              border: 1px solid $primary-color;
              pointer-events: none;
              opacity: 0;
              transition: 0.4s;
              form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                input {
                  height: 3.5rem;
                  width: 85%;
                  border-radius: 2.5rem;
                  border: 0;
                  outline: 0;
                  background-color: transparent;
                  font-size: 1.6rem;
                  padding-left: 1rem;
                }
                button {
                  height: 4rem;
                  width: 4rem;
                  background-color: $primary-color;
                  border-radius: 50%;
                }
              }
              &._active {
                pointer-events: all;
                opacity: 1;
                bottom: 0rem;
                @include big-tab() {
                  bottom: -6rem;
                }
              }
            }
          }
          .language-wrap {
            button {
              align-items: center;
              justify-content: space-around;
              height: 100%;
              font-size: 1.6rem;
              border: 0;
              border-left: 1px solid $white-color;
              border-right: 1px solid $white-color;
              outline: 0;
              background-color: transparent;
              color: $white-color;
              padding: 0 1rem;
              transition: 0.4s ease-in-out;
              width: 12rem;
              display: none;
              @include small-tab() {
                display: flex;
              }
              &:hover {
                background-color: $primary-color;
              }
              img {
                width: 2.1rem;
                margin-right: 0.6rem;
                margin-bottom: 0.2rem;
                &:last-child {
                  margin-right: 0;
                  margin-left: 0.6rem;
                }
              }
              span {
                color: $white-color;
              }
            }

            .goog-te-combo {
              display: block;
              width: 100%;
              padding: 0.375rem 0.75rem;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1.5;
              color: #fff;
              background-color: transparent;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              border-radius: 0.25rem;
              transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
              border: none;
              margin: 0;
            }

            .skiptranslate.goog-te-gadget {
              border-left: 1px solid #fff;
              font-size: 1rem;
              padding-left: 1rem;
              margin: 0 1rem 0 0;
              @include big-tab() {
                border-left: unset;
              }
            }
          }
        }
      }
    }
    .middle-nav-wrap {
      .middle-nav {
        border-bottom: 1px solid rgba($color: $grey, $alpha: 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        .logo {
          a {
            img {
              width: 15rem;
              @include small-tab() {
                width: 20rem;
              }
              @include big-tab() {
                width: 25rem;
              }
            }
          }
        }
        .search-bar {
          display: none;
          @include big-tab() {
            display: block;
          }
          form {
            border: 1px solid $primary-color;
            display: flex;
            align-items: center;
            border-radius: 25px;
            overflow: hidden;
            position: relative;
            width: 250px;
            @include desktop() {
              width: 400px;
            }
            input {
              width: 90%;
              height: 40px;
              padding-left: 15px;
              border: 0;
              outline: 0;
              background-color: $white-color;
              font-size: 1.6rem;
              padding-right: 12%;
              @include desktop() {
                padding-right: 16%;
              }
            }
            button {
              position: absolute;
              top: 0;
              right: 0;
              aspect-ratio: 1;
              border: 0;
              outline: 0;
              background-color: $primary-color;
              font-size: 1.6rem;
              @include center();
              border-radius: 50%;
              width: 17%;
              @include desktop() {
                width: 25%;
                height: 40px;
                border-radius: 25px;
              }
              span {
                color: $white-color;
                display: none;
                @include desktop() {
                  display: block;
                }
              }
            }
          }
        }
        .auth-wrap {
          & > ul {
            display: flex;
            align-items: center;
            & > li {
              &.auth-buttons {
                display: flex;
                align-items: center;
                margin-right: 2rem;
                @include mobile() {
                  margin-right: 3rem;
                }
                button {
                  outline: 0;
                  margin: 0;
                  font-size: 1.8rem;
                  padding: 0.4rem 1.2rem;
                  background-color: transparent;
                  border: 1px solid $primary-color;
                  transition: 0.4s;
                  &:hover {
                    background-color: #f7cc75;
                  }
                  &:first-child {
                    border-top-left-radius: 0.6rem;
                    border-bottom-left-radius: 0.6rem;
                    background-color: #f7cc75;
                    border-right: 0;
                  }
                  &:last-child {
                    border-radius: 0.6rem;
                    @include mobile() {
                      border-radius: unset;
                      border-top-right-radius: 0.6rem;
                      border-bottom-right-radius: 0.6rem;
                      border-left: 0;
                    }
                  }
                }
              }
              &.user {
                position: relative;
                & > button {
                  border: 0;
                  outline: 0;
                  background-color: $grey;
                  width: 3.4rem;
                  height: 3.4rem;
                  padding: 0.6rem;
                  border-radius: 50%;
                  margin-right: 1.5rem;
                  img {
                    width: 100%;
                  }
                }
                &:hover {
                  ._dropdown {
                    pointer-events: all;
                    opacity: 1;
                  }
                }
                ._dropdown {
                  position: absolute;
                  top: 4.5rem;
                  border: 1px solid #00000026;
                  width: 15rem;
                  right: 0;
                  background-color: #f9f9f9;
                  z-index: 10;
                  border-radius: 1.2rem;
                  pointer-events: none;
                  opacity: 0;
                  &::after {
                    content: "";
                    position: absolute;
                    top: -1.5rem;
                    width: 100%;
                    height: 2rem;
                    left: 0;
                  }
                  &::before {
                    content: "";
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    right: 2rem;
                    top: -1rem;
                    background-color: #f9f9f9;
                    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                  }
                  ul {
                    li {
                      a,
                      button {
                        font-size: 1.6rem;
                        display: block;
                        text-align: left;
                        width: 100%;
                        padding: 0.7rem 0;
                        background-color: transparent;
                        border: 0;
                        outline: 0;
                        color: $font-color;
                        padding: 1rem 2.5rem;
                        transition: 0.4s;
                        &:hover {
                          color: $primary-color;
                        }
                      }
                    }
                  }
                }
              }
              &.cart {
                button {
                  position: relative;
                  border: 0;
                  outline: 0;
                  width: 3.4rem;
                  height: 3.4rem;
                  background-color: transparent;
                  span {
                    position: absolute;
                    font-size: 1.2rem;
                    right: -1rem;
                    top: -1.3rem;
                    min-width: 2rem;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $primary-color;
                    color: $white-color;
                    border-radius: 50%;
                  }
                  img {
                    width: 100%;
                  }
                }
              }
              &.mob-nav {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: 1rem;
                button {
                  height: 4.25rem;
                  width: 4.25rem;
                  border: 0;
                  background-color: transparent;
                  outline: 0;
                  img {
                    width: 65%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottom-nav-wrap {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.4rem;
        bottom: 0;
        left: 0;
        background-color: $secondary-color;
        z-index: 0;
      }
      .main-nav-list {
        align-items: center;
        padding-left: 0;
        grid-template-columns: repeat(10, minmax(0, 1fr));
        display: none;
        list-style-type: none;
        margin-bottom: 0;
        position: relative;
        @include small-tab() {
          display: grid;
        }
        li {
          position: relative;
          .main-nav-link {
            font-size: 1.4rem;
            @include big-tab() {
              font-size: 1.7rem;
            }
            @include desktop() {
              font-size: 1.8rem;
            }
            text-decoration: none;
            color: $font-color;
            font-weight: 600;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;
            border-bottom: 0.4rem solid $secondary-color;
            transition: 0.4s;
            &:hover {
              color: $white-color;
              background-color: $primary-color;
              border-bottom: 0.4rem solid $primary-color-dark;
            }
          }
          &.dropdown {
            .dropdown-menu {
              opacity: 0;
              filter: none;
              min-width: 20rem;
              min-height: 5rem;
              position: absolute;
              column-gap: 20px;
              top: 90px;
              z-index: 350;
              pointer-events: none;
              background-color: $white-color;
              display: flex;
              padding: 0;
              transition: 0.4s all ease-in-out;
              left: 0;
              border-radius: 4px;
              min-width: 17rem;

              @include big-tab() {
                min-width: 25rem;
              }

              @include desktop() {
                min-width: 27rem;
              }

              &._last-dropdown{
                right: 0;
                left: unset;
              }

              &.active {
                opacity: 1;
                top: 55px;
                pointer-events: all;
              }
              // &::after {
              //   content: "";
              //   position: absolute;
              //   width: 15px;
              //   height: 10px;
              //   background-color: $white-color;
              //   top: -9px;
              //   left: 70px;
              //   clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
              // }
              &::before {
                content: "";
                background-color: transparent;
                height: 14px;
                width: 100%;
                position: absolute;
                top: -13px;
                left: 0;
              }
              .dropdown-list {
                list-style-type: none;
                margin-bottom: 0;
                width: 100%;
                padding-left: unset;
                border-bottom: 3px solid $primary-color;
                li {
                
                  border-bottom: 2px solid transparent;
                  transition: 0.3s;
                  border-bottom: 1px solid $light-grey;
                  a{
                     padding: 9px 25px;
                  }
                  &:first-child {
                    border-radius: 4px 4px 0 0;
                    a{
                      padding: 15px 25px 11px 25px !important;
                    }
                  }
                  &:last-child {
                    border-bottom-style: none;
                    a{
                       padding: 11px 25px 15px 25px;
                    }
                  }
                  a {
                    position: relative;
                    font-size: 1.9rem;
                    font-weight: 500;
                    white-space: nowrap;
                    color: $font-color;
                    transition: 0.3s;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                  }
                  &:hover {
                    a {
                      color: $white-color;
                    }
                    background-color: $primary-color-dark;
                  }
                }
              }
            }
          }
        }
      }
      .mob-nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
          height: 4.25rem;
          width: 4.25rem;
          border: 0;
          background-color: transparent;
          outline: 0;
          img {
            width: 65%;
          }
        }
      }
    }
  }

  .cart-dropdown {
    min-width: 40rem;
    padding: 2rem;
    position: absolute;
    right: 0;
    background-color: $white-color;
    z-index: 5;
    border-radius: 0 0 12px 12px;
    .dropdown-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .collapse-close-btn {
        background-color: transparent;
        border-style: none;
      }
      h5 {
        font-size: 2rem;
      }
    }
    .dropdown-body {
      .dropdown-product-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 0;
        figure {
          height: 8rem;
          width: 8rem;
          border: 1px solid #eeeeee;
          img {
            height: 100%;
            width: 100%;
          }
        }
        ul {
          list-style-type: none;
          li {
            p {
              color: $grey;
              margin-bottom: 0;
            }
            &.bold {
              p {
                font-weight: 600;
                color: $font-color;
              }
            }
          }
        }
      }
      .dropdown-btn-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
        button {
          @include center();
          width: 100%;
        }
      }
    }
  }
}

section.banner {
  position: relative;
  .banner-slider {
    .splide__slide {
      position: relative;
      .banner-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #46545b6b;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .container {
        height: 100%;
        overflow: hidden;
      }
      .banner-content-wrap {
        height: 100%;
        position: relative;
        .row {
          height: 100%;
        }
        .banner-content {
          height: 100%;
          display: flex;
          align-items: center;
          & > div {
            @include desktop() {
              max-width: 80%;
            }
            span.top-line {
              width: 7rem;
              height: 0.35rem;
              background-color: $primary-color;
              display: inline-block;
            }
            h4 {
              margin-top: 1.8rem;
              font-size: 2.2rem;
              font-weight: 400;
              margin-bottom: 2.5rem;
              color: $white-color;
            }
            h1 {
              color: $white-color;
              font-size: 3rem;
              @include mobile() {
                font-size: 4rem;
              }
              &.small-text {
                font-size: 2.8rem;
                @include big-tab() {
                  font-size: 4rem;
                }
              }
            }
            p {
              color: $white-color;
              margin: 1.5rem 0;
              @include mobile() {
                margin: 3.5rem 0;
              }
              @include small-tab() {
                font-size: 2rem;
              }
            }
          }
          &.transform-left {
            transform: translate(-60%, 0) !important;
            opacity: 0 !important;
            transition: 0.8s !important;
            &.active {
              transform: translate(0%, 0) !important;
              opacity: 1 !important;
            }
          }
        }
        .banner-quote {
          height: 100%;
          position: relative;
          display: flex;
          align-items: flex-end;
          & > div {
            width: 100%;
            border-top-left-radius: 15rem;
            background-color: rgba($color: $primary-color, $alpha: 0.7);
            padding: 4rem 2rem 4rem;
            @include big-tab() {
              padding: 10rem 4rem 8rem;
            }
            @include desktop() {
              padding: 10rem 5.5rem 8rem;
            }
            h4 {
              text-align: center;
              margin-bottom: 0;
              color: $white-color;
              padding-left: 2rem;
              font-size: 2.1rem;
              @include big-tab() {
                font-size: 2.5rem;
                padding-left: 0;
              }
            }
          }
        }
        h4 {
          &.transform-top {
            font-size: 2.5rem;
            text-align: center;
          }
        }
        .banner-headings-list {
          padding-left: 0;
          height: min-content;
          display: flex;
          list-style-type: none;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          row-gap: 1rem;
          @include desktop() {
            row-gap: 3rem;
          }
          .main-link {
            background-color: rgba($color: $primary-color, $alpha: 0.7);
            border-radius: 1.5rem 0 0 1.5rem;
            padding: 1rem 1.5rem;
            @include mobile() {
              border-radius: 3.5rem 0 0 3.5rem;
              padding: 2rem;
            }
            @include desktop() {
              padding: 3rem;
            }
            h4 {
              margin-bottom: 0;
              font-size: 2.1rem;
              color: $white-color;
              @include big-tab() {
                font-size: 2.5rem;
              }
            }
            &.transform-right {
              transform: translate(60%, 0) !important;
              opacity: 0 !important;
              transition: 0.8s !important;
              transition-delay: 0.2s !important;
              &.active {
                transform: translate(0%, 0) !important;
                opacity: 1 !important;
              }
            }
            &.transform-right-2 {
              transform: translate(60%, 0) !important;
              opacity: 0 !important;
              transition: 0.8s !important;
              transition-delay: 0.5s !important;
              &.active {
                transform: translate(0%, 0) !important;
                opacity: 1 !important;
              }
            }
            &.transform-right-3 {
              transform: translate(60%, 0);
              opacity: 0;
              transition: 0.8s;
              transition-delay: 0.8s;
              &.active {
                transform: translate(0%, 0);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .transform-top {
      transform: translate3d(0, 150px, 0);
      opacity: 0;
      transition: 0.5s;
      transition-delay: 0s;
      &.active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
  .splide__pagination {
    // bottom: unset;
    bottom: 4rem;
    gap: 2rem;
    // right: 2rem;
    // transform: translateY(-50%);
    // flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-end;
    li {
      button {
        background-color: $white-color;
        opacity: 1;
        width: 1rem;
        height: 1rem;
        margin-top: 2rem;
        &.is-active {
          transform: unset;
          background-color: $primary-color;
        }
      }
    }
  }
}

section.search-by-index {
  background-color: #fef8ea;
  .letters-warp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
  }
  button {
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    background-color: $white-color;
    border: 0;
    outline: 0;
    font-size: 1.8rem;
    font-weight: 700;
    color: $secondary-color;
    box-shadow: 0 0 10px 5px #cb911721;
    transition: 0.4s;
    margin: 0 1rem;
    margin-bottom: 1rem;
    @include desktop() {
      margin: 0 1.12rem;
      margin-bottom: 1rem;
    }
    &:hover,
    &.active {
      color: $white-color;
      background-color: $primary-color;
    }
  }
}

section.who-we-are {
  padding-bottom: 15rem;
  position: relative;
  overflow: hidden;
  @include small-tab() {
    padding-bottom: 10rem;
  }
  @include desktop() {
    padding-bottom: 18rem;
  }
  .custom-btn {
    margin-top: 3rem;
    @include mobile() {
      margin-top: 5rem;
    }
  }
  figure {
    position: relative;
    width: 23rem;
    height: 23rem;
    margin-left: 4rem;
    @include small-tab() {
      width: 32rem;
      height: 32rem;
      margin-left: 12rem;
    }
    @include big-tab() {
      margin-left: unset;
      width: 25rem;
      height: 25rem;
    }
    @include desktop() {
      width: 37rem;
      height: 37rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.5rem;
      overflow: hidden;
      &.first {
        position: absolute;
        top: 10rem;
      }
      &.second {
        position: absolute;
        right: -9rem;
        @include mid-small-mobile() {
          right: -15rem;
        }
        top: -1rem;
        @include big-tab() {
          right: -12rem;
        }
        @include desktop() {
          right: -25rem;
        }
      }
    }
  }
  figure.bg-lines {
    position: absolute;
    width: 50%;
    bottom: -11.2rem;
    left: 0;
    pointer-events: none;
    display: none;
    @include big-tab() {
      transform: rotate(-20deg);
    }
    @include desktop() {
      transform: rotate(0deg);
    }
    @include big-tab() {
      display: block;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
    }
    &._top {
      bottom: unset;
      top: 0rem;
      left: unset;
      right: 0;
      transform: rotate(-20deg);
    }
  }
}

section.specialties {
  background-color: #fef8ea;
  position: relative;
  z-index: 2;
  overflow: hidden;
  .specialties-card-wrap {
    margin-top: 6rem;
    .specialties-card {
      height: 100%;
      border-radius: 1.2rem;
      background-color: $white-color;
      box-shadow: 0 0 20px 5px #f3ad1b1f;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.4s;
      min-height: 22rem;
      @include desktop() {
        min-height: 25rem;
      }
      figure {
        width: 8.5rem;
        height: 8.5rem;
        background-color: rgba($color: $primary-color, $alpha: 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: auto;
        transition: 0.4s;
        img,
        svg {
          width: 40%;
          transition: 0.4s;
          fill: $white-color;
          stroke: $white-color;
        }
      }
      h4 {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 0;
        font-size: 1.8rem;
        @include desktop() {
          font-size: 2rem;
        }
      }
      &:hover {
        background-color: $primary-color;
        transform: translateY(-10px);
        figure {
          background-color: $white-color;
          svg {
            fill: $primary-color;
            stroke: $primary-color;
          }
        }
        h4 {
          color: $white-color;
        }
      }
    }
  }
  .float-elem {
    position: absolute;
    width: 40%;
    border: 1px;
    transform: rotate(14deg);
    top: 5rem;
    pointer-events: none;
    z-index: -1;
    &._tl {
      left: 0;
    }
    &._tr {
      right: 0;
      top: 3rem;
    }
    &._bl {
      top: unset;
      bottom: -2rem;
      left: 0;
    }
    &._br {
      top: unset;
      bottom: 20rem;
      right: 0;
      transform: rotate(0deg);
    }
    &._sec {
      bottom: 8rem;
    }
  }
}

section.cta {
  background-color: #fad78e;
  &._slider {
    padding: 10rem 0 15rem;
    background-color: #f8ce761a;
  }
  &._cta-slider {
    h4 {
      text-align: center;
      font-weight: 600;
      margin-bottom: 0;
      font-size: 2.7rem;
      @include mobile() {
        font-size: 3.6rem;
      }
      @include desktop() {
        font-size: 4.5rem;
      }
    }
  }
  &._text {
    padding: 8rem 0;
    background-color: #f8ce761a;
    @include small-tab() {
      padding: 15rem 0;
    }
  }
  &._gradient {
    background-image: url("/assets/images/homepage/cta/cta-bg.jpg");
    @include background(center, fixed);
  }
  &._gradient-invert {
    background-image: url("/assets/images/homepage/cta/cta-last.jpg");
    @include background(center, fixed);
    @include small-tab() {
      padding: 10rem 0;
    }
  }
  h4 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 3rem;
    @include mobile() {
      font-size: 4rem;
    }
    @include desktop() {
      font-size: 5rem;
    }
  }
  h5 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 2.5rem;
    @include mobile() {
      font-size: 3.5rem;
    }
    @include desktop() {
      font-size: 4.5rem;
    }
  }
  .splide__pagination {
    bottom: -8rem;
    button {
      width: 1rem;
      height: 1rem;
      margin: 0 1rem;
      background-color: $grey;
      &.is-active {
        transform: unset;
        background-color: $primary-color;
        opacity: 1;
      }
    }
  }
  .btn-wrap {
    margin-top: 3rem;
    @include small-tab() {
      margin-top: 5rem;
    }
  }
}

section.our-products {
  background-color: #efefef;
  position: relative;
  padding-bottom: 13rem;
  .products-slider-wrap {
    position: relative;
    z-index: 2;
    .splide__pagination {
      bottom: -3rem;
      @include small-tab() {
        bottom: -5rem;
      }
      button {
        border-radius: 0;
        margin: 0;
        height: 0.5rem;
        width: 6rem;
        background-color: rgba($color: $grey, $alpha: 0.4);
        transition: 0.4s;
        &.is-active {
          background-color: $primary-color;
          transform: unset;
        }
      }
    }
  }
  .custom-slider-arrows {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    width: 100%;
    display: none;
    @include small-tab() {
      display: flex;
    }
    @include desktop() {
      width: 90%;
    }
    button {
      width: 4rem;
      height: 4rem;
      border: 0;
      outline: 0;
      img {
        width: 100%;
      }
    }
  }
  .custom-progress {
    position: absolute;
    bottom: 8rem;
    height: 0.4rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .progress {
      width: 20%;
      height: 100%;
      background-color: rgba($color: $grey, $alpha: 0.1);
      position: relative;
      span {
        width: 25%;
        background-color: $primary-color;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.4s;
      }
    }
  }
}

section.csr {
  background-color: #efefef;
  .csr-categories-slider {
    li.splide__slide {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;
      button {
        font-size: 2.2rem;
        padding: 0.8rem 2rem;
        width: 80%;
        border-radius: 2.5rem;
        border: 2px solid $white-color;
        background-color: $white-color;
        box-shadow: 0 6px 15px 3px #e9e9e9;
        transition: 0.4s;
        &:hover {
          background-color: $primary-color;
          color: $white-color;
          border-color: $primary-color;
        }
      }
    }
    .splide__arrows {
      button {
        background-color: transparent;
        svg {
          width: 2.2rem;
          height: 2.2rem;
          fill: $grey;
        }
        &.splide__arrow--prev {
          @include small-tab() {
            left: -1rem;
          }
        }
        &.splide__arrow--next {
          @include small-tab() {
            right: -1rem;
          }
        }
      }
    }
  }
  .csr-slider {
    margin-top: 4rem;
    li.splide__slide {
      padding: 2rem;
      .card-wrap {
        box-shadow: 0 0 22px #c6cccf5c;
        background-color: $white-color;
        border-radius: 2rem;
        height: 100%;
        overflow: hidden;
        min-height: 42rem;
        @include desktop() {
          min-height: 48rem;
        }
        & > .row {
          height: 100%;
        }
        figure {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .csr-card-content {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 2rem;
          @include desktop() {
            padding: 6rem;
          }
          h4 {
            font-size: 3rem;
            font-weight: 700;
          }
          h6 {
            font-size: 2.4rem;
            margin-top: 1.5rem;
          }
          p {
            margin-top: 2.2rem;
          }
          button {
            margin-top: 2rem;
            @include big-tab() {
              margin-top: 5rem;
            }
          }
        }
      }
    }
  }
  .custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    button {
      border: 0;
      outline: 0;
      background-color: transparent;
      img {
        width: 2.6rem;
      }
    }
    & > span {
      font-size: 2rem;
      color: $secondary-color;
      font-weight: 600;
      margin: 0 1rem;
    }
    .slider-progress {
      width: 25%;
      margin: 0 1rem;
      background-color: rgba($color: $grey, $alpha: 0.1);
      height: 0.5rem;
      border-radius: 2.5rem;
      position: relative;
      span {
        position: absolute;
        width: 50%;
        height: 100%;
        background-color: $primary-color;
        border-radius: 2.5rem;
        transition: 0.4s;
      }
    }
  }
  .custom-arrows {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    display: none;
    @include mobile() {
      display: flex;
    }
    button {
      border: 0;
      background-color: transparent;
      position: absolute;
      img {
        width: 2.6rem;
        &:last-child {
          margin-left: -1.8rem;
        }
      }
      &:first-child {
        left: -2rem;
        @include big-tab() {
          left: -4rem;
        }
      }
      &:last-child {
        right: -2rem;
        @include big-tab() {
          right: -4rem;
        }
      }
    }
  }
}

section.blogs {
  background-image: url("/assets/images/homepage/blogs/blog-bg.jpg");
  @include background(center, fixed);
  .blogs-slider-wrap {
    margin-top: 4rem;
    .blog-slider {
      li.splide__slide {
        position: relative;
        padding: 2rem;
        @include big-tab() {
          padding: 3rem;
        }
        &::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          background-color: rgba($color: $grey, $alpha: 0.6);
          top: 0;
          right: -5px;
        }
        figure {
          width: 100%;
          min-height: 20rem;
          box-shadow: 0 0 30px #8d8d8d8f;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        & > p,
        a {
          margin-top: 4rem;
          font-size: 2rem;
          display: block;
          color: $font-color;
          text-decoration: none;
        }
        .card-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 4rem;
          span {
            font-size: 1.6rem;
            color: rgba($color: $font-color, $alpha: 0.6);
          }
          a {
            font-size: 1.6rem;
            text-decoration: none;
            padding: 0.5rem 1.8rem;
            border-radius: 2.5rem;
            color: $font-color;
            background-color: rgba($color: $primary-color, $alpha: 0.7);
            border: 2px solid rgba($color: $primary-color, $alpha: 0.3);
            transition: 0.4s;
            &:hover {
              background-color: $white-color;
              border-color: $white-color;
            }
          }
        }
      }
    }
  }
  .custom-arrows {
    margin-top: 4rem;
    .slider-progress {
      width: 100%;
      height: 0.5rem;
      background-color: rgba($color: $grey, $alpha: 0.1);
      position: relative;
      border-radius: 2.5rem;
      span {
        position: absolute;
        width: 25%;
        height: 100%;
        background-color: $primary-color;
        border-radius: 2.5rem;
        transition: 0.4s;
      }
    }
    .arrows-wrap {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      button {
        background-color: transparent;
        border: 0;
        img {
          width: 2.6rem;
          &:last-child {
            margin-left: -1.8rem;
          }
        }
      }
      span {
        font-size: 1.8rem;
        position: absolute;
        left: 5rem;
        color: $secondary-color;
        font-weight: 600;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

footer {
  background-color: #fffcfc;
  padding: 6rem 0 1rem 0;
  p {
    color: $dark-grey;
  }
  .list-heading {
    color: $secondary-color;
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  .footer-left-wrapper {
    .footer-logo {
      border-bottom: 0.1rem solid $primary-color;
      padding-bottom: 2rem;
      width: 100%;
      figure {
        width: 80%;
        img {
          width: 100%;
        }
      }
    }
    p {
      color: $dark-grey;
    }
    .social-links-wrap {
      margin: 2.5rem 0 1rem 0;
      @include mobile() {
        margin: 2.5rem 0 4rem 0;
      }
      .socials-wrapper {
        display: flex;
        column-gap: 2.5rem;
        li {
          .socials {
            height: 4rem;
            width: 4rem;
            border-radius: 50%;
            border: 0.2rem solid $primary-color;
            color: $primary-color;
            @include center();
            transition: 0.3s background-color ease-in-out;
            opacity: 1;
            figure {
              margin: 0;
              @include center();
              height: 2rem;
              width: 2rem;
              img {
                height: 100%;
                width: 100%;
              }
            }
            .socials-icon {
              margin: 0;
              @include center();
              height: 2rem;
              width: 2rem;
              svg {
                transition: 0.3s fill ease-in-out;
                height: 100%;
                width: 100%;
                fill: $primary-color;
              }
              .cls-2{
                transition: 0.3s;
              }
            }
            &:hover {
              background-color: $primary-color;
              svg {
                fill: $white-color;
              }
              .cls-2{
                fill: $white-color;
                stroke: $white-color;
              }

              & > * {
                transition: 0.4s;
                color: $white-color;
              }
            }
          }
          .tooltip {
            position: relative;
            z-index: 1;
          }

          .tooltip .tooltip-text {
            visibility: hidden;
            width: 12rem;
            background-color: $secondary-color;
            color: #fff;
            text-align: center;
            border-radius: 0.6rem;
            padding: 0.5rem 0;
            position: absolute;
            z-index: 1;
            top: 150%;
            left: 50%;
            margin-left: -6rem;
            font-size: 1.4rem;
          }

          .tooltip .tooltip-text::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            border-width: 0.5rem;
            margin-left: -0.5rem;
            border-style: solid;
            border-color: transparent transparent $secondary-color transparent;
          }

          .tooltip:hover .tooltip-text {
            visibility: visible;
            transition: 0.3s;
            @include too-small-mobile() {
              margin-top: -0.8rem;
            }
            @include small-desktop() {
              margin-top: -1rem;
            }
          }
        }
      }
    }
  }
  .quick-links-wrapper {
 
    flex-wrap: wrap;
    .list-wrap{
   display: flex;
    column-gap: 4.5rem;
    }
  }
  .footer-links-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 1.8rem;
    @include big-tab() {
      margin-top: 2rem;
    }

    li {
      a {
        color: $dark-grey;
        font-size: 2rem;
        transition: 0.4s;
        @include small-tab() {
          font-size: 1.8rem;
        }
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
  .bottom-footer {
    border-top: 0.1rem solid;
    border-bottom: 0.1rem solid;
    width: 100%;
    border-image-slice: 1;
    border-width: 0.1rem;
    border-image-source: linear-gradient(to left, $primary-color, $secondary-color);
    @include mobile() {
      margin: 0 0 0 0;
    }
    @include big-tab() {
      border-bottom: none;
    }
    padding: 3.2rem 0;
  }
  .footer-address-wrapper {
    display: flex;
    column-gap: 2rem;
    h5 {
      color: $dark-grey;
    }

    .secondary-figure {
      height: 4rem;
      min-width: 4rem;
      border: 0.2rem solid $secondary-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 2rem;
        width: 2rem;
        fill: $secondary-color;
      }
      &:hover {
        background-color: $secondary-color;
        transition: 0.3s all ease-in-out;
        svg {
          fill: $white-color;
        }
      }
    }
    h5 {
      font-size: 2rem;
      color: $font-color;
    }
    a {
      font-size: 2rem;
      text-decoration: none;
      @include small-tab() {
        font-size: 1.8rem;
      }
      color: $dark-grey;
    }
  }
  .bottom-links-footer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-top: 0.1rem solid;
    border-image-slice: 1;
    border-width: 0.1rem;
    border-image-source: linear-gradient(to left, $primary-color, $secondary-color);
    padding-top: 2.5rem;
    @include big-tab() {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    a {
      font-size: 2rem;
      @include small-tab() {
        font-size: 1.6rem;
      }
      color: $dark-grey;
      &:hover {
        color: $secondary-color;
      }
    }
  }
}

section.page-header {
  position: relative;
  z-index: 2;
  padding: 8rem 0;
  @include mobile() {
    padding: 12rem 0;
  }
  & > .ph-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #a32b2321;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .page-header-card {
    position: relative;
    z-index: 2;
    padding: 3rem;
    @include small-tab() {
      padding: 3rem 4rem;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $white-color;
      opacity: 0.9;
      z-index: -1;
    }
    &::before {
      content: "";
      width: 100%;
      height: 0.5rem;
      background-color: $secondary-color;
      position: absolute;
      left: 0;
      top: 0;
    }
    h4.sub-heading {
      font-size: 2rem;
      color: $secondary-color;
      margin-bottom: 3rem;
    }
    h2 {
      font-size: 3rem;
      font-weight: 600;
    }
    p {
      margin-bottom: 0;
      margin-top: 3rem;
      color: $grey;
    }
  }
  &.csr-detail {
    background-color: #feecc4;
    h2 {
      font-size: 3rem;
      font-weight: 600;
    }
    .side-img {
      height: 40rem;
      object-fit: cover;
    }
  }
  figure.csr-detail-page-header {
    width: 200px;
    margin: auto;
    img {
      width: 100%;
    }
  }
}

section.product-list {
  .backdrop {
    position: static;
    display: none;
    scale: 0;

    &.active {
      scale: 1;
      display: block;
      width: 100vw;
      height: 100vh;
      position: fixed;
      background-color: #010a3470;
      z-index: 10;
      top: 0;
      left: 0;
    }
  }

  .accordion-item {
    position: relative;
    z-index: 1999;
  }

  .accordion-button {
    padding: 1.6rem 1rem;
    @include mobile() {
      padding: 1.6rem 2rem;
    }

    &:not(.collapsed) .filter-btn {
      color: $primary-color;

      svg {
        stroke: $primary-color;
      }
    }
  }

  .product-search {
    width: 70%;
    input {
      padding-left: 0.5rem;
      width: 70%;
      height: 3rem;
      @include mobile() {
        padding-left: 1rem;
      }
      border: none;
      font-size: 1.6rem;
      &:focus-visible {
        outline: none;
      }
    }
    svg {
      margin-bottom: 0.5rem;
    }
  }
  .filter-btn {
    background-color: transparent;
    border: none;
    color: $grey;
    border-left: 0.2rem solid #dfdfdf;
    padding-left: 0.5rem;
    font-size: 1.6rem;
    @include center();
    gap: 0.4rem;
    @include mobile() {
      padding-left: 1.5rem;
    }
    @include small-tab() {
      gap: 1rem;
      padding-left: 2.5rem;
      padding-right: 1.5rem;
    }
    transition: all 0.4s ease;

    svg {
      transition: all 0.4s ease;
      fill: transparent;
      stroke: $grey;
    }
  }

  input[name="filter-badge"] {
    display: none;
    transition: 300ms;
  }

  .badge-label {
    padding: 0.7rem 1.6rem;
    padding-left: 1.1rem;
    background-color: #ffffff;
    border: 0.1rem solid $primary-color;
    border-radius: 10rem;
    color: #374151;
    user-select: none;
    transition: 300ms;
    display: inline-flex;

    &:hover {
      cursor: pointer;
    }
  }

  .close-check-tag {
    width: 0;
    margin: -0.15rem -0.9rem 0 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  input[name="filter-badge"]:checked + .badge-label {
    transition: 300ms;
    background-color: #f6c1532e;
    border-color: $primary-color;
    color: $primary-color;
    font-size: 1.6rem;
    .close-check-tag {
      width: 2.1rem;
    }

    &:hover {
      cursor: auto;
    }
  }
  .filter-wrap {
    .accordion-item {
      box-shadow: 0.2rem 0.8rem 4rem rgba(0, 0, 0, 0.08);
      border: none;
      border-radius: 1.5rem;

      .accordion-button {
        background-color: #fff;
        border-radius: 1.5rem;

        &:focus {
          box-shadow: none;
        }
        &::after {
          display: none;
        }
        &:not(.collapsed) {
          box-shadow: inset 0 -0.05rem 0 rgba(0, 0, 0, 0.125);
          border: none;
          color: initial;
        }
      }
    }
  }
  .filter-checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;
    input {
      opacity: 0;
      width: 2.2rem;
      height: 1.8rem;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      height: 2.2rem;
      width: 2.2rem;
      border-radius: 0.4rem;
      background-color: transparent;
      border: 0.2rem solid #dfdfdf;

      &::after {
        content: "";
        position: absolute;
        left: 0.8rem;
        top: 0.5rem;
        width: 0.6rem;
        height: 1rem;
        border: solid white;
        border-width: 0 0.3rem 0.3rem 0;
        transform: rotate(45deg);
        display: none;
      }
    }

    input:checked ~ .checkmark {
      background-color: $primary-color;
      border: none;
      &:after {
        display: block;
      }
    }
    label {
      padding-left: 9px;
      font-size: 1.6em;
    }

    * {
      cursor: pointer;
    }
  }
}

section._breadcrumb {
  background-color: rgba($color: $primary-color, $alpha: 0.6);
  border-bottom: 1px solid #a32b230d;
  nav {
    --bs-breadcrumb-divider: ">";
    .breadcrumb {
      align-items: center;
      li {
        font-size: 1.6em;
        color: $secondary-color;
        font-weight: 600;
        &::before {
          margin: 0 1rem;
        }
        a {
          color: $secondary-color;
        }
      }
      .back-btn{
        background-color: transparent;
        border-radius: 50em;
        border: 2px solid $secondary-color;
        display: flex;
        text-decoration: none;
        align-items: center;
        gap: 5px;
        padding: 3px 15px;
        color: $secondary-color;
        margin-right: 15px;
        span{
          @include center();
          height: 15px;
          width: 15px;
          svg{
            height: 100%;
            width: 100%;
            fill: $secondary-color;
          }
        }
      }
    }
  }
}

section.product-info {
  padding-top: 3rem;
  @include small-desktop() {
    padding-top: 4rem;
  }
  @include desktop() {
    padding-top: 8rem;
  }
  .molecule-wrap {
    position: sticky;
    top: 4rem;
    height: 100%;
    ._main {
      height: 100%;
      border: 1px solid #f3ad1b5e;
      // box-shadow: 0 0 20px #ae82223b;
      padding: 1rem;
      border-radius: 0.8rem;
      background-color: $white-color;
      position: relative;
      .present-type {
        border: 1px solid $primary-color;
        font-size: 1.6rem;
        padding: 0.2rem 2rem;
        border-radius: 0.5rem;
        outline: 0;
        width: 100%;
      }
      .t-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .d-toggle {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          input {
            display: none;
            &[type="checkbox"]:checked + label::before {
              left: unset;
              right: 0;
            }
          }
          label {
            width: 3.5rem;
            height: 2rem;
            background-color: rgba($color: $grey, $alpha: 0.4);
            border-radius: 2.5rem;
            position: relative;
            transition: 0.4s;
            cursor: pointer;
            &::before {
              position: absolute;
              content: "";
              width: 2rem;
              height: 2rem;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              right: unset;
              background-color: $grey;
              z-index: 1;
              border-radius: 50%;
              pointer-events: none;
            }
          }
          span {
            font-size: 1.6rem;
            font-weight: 500;
            @include big-tab() {
              font-size: 1.5rem;
            }
            &:first-child {
              margin-right: 0.8rem;
            }
            &:last-child {
              margin-left: 0.6rem;
            }
          }
        }
        .toggle-molecule-mode {
          margin-right: 2rem;
          border: 0;
          outline: 0;
          background-color: transparent;
          border-radius: 50%;
          img {
            width: 2rem;
          }
        }
        .visualizer-actions {
          font-size: 1.6rem;
          border-radius: 0.3rem;
          border: 0;
          outline: 0;
          padding: 0.5rem 1rem;
          color: $primary-color;
          background-color: #f4b32b1c;
          margin-left: 10px;
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      .main-fig {
        border: 1px solid #f3ad1b26;
        width: 100%;
        // width: 30rem;
        height: 30rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        margin: auto;
        margin-top: 2rem;
        @include large-desktop() {
          height: 40rem;
        }
        img {
          aspect-ratio: 1/1;
          object-fit: contain;
        }
      }
      .mol-btn-wrap {
        display: flex;
        justify-content: flex-end;
        // margin-top: 1rem;
        button {
          font-size: 1.6rem;
          border-radius: 0.3rem;
          border: 0;
          outline: 0;
          padding: 0.5rem 1rem;
          color: $primary-color;
          background-color: #f4b32b1c;
          @include big-tab() {
            font-size: 1.4rem;
          }
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      .card_bottom_wrap {
        display: flex;
        justify-content: space-between;
        // @include desktop() {
        //   flex-direction: column;
        // }
        .color-select {
          border: 1px solid $primary-color;
          font-size: 1.6rem;
          padding: 0.2rem 2rem;
          border-radius: 0.5rem;
          outline: 0;
          height: 3rem;
          @include big-tab() {
            padding: 0 1rem;
          }
        }
      }
    }
  }
  .product-description {
    background-color: $white-color;
    padding: 1rem;
    border-radius: 0.8rem;
    border: 1px solid #f3ad1b5e;
    position: relative;
    z-index: 1;
    height: 100%;
    @include small-tab() {
      display: flex;
      flex-direction: column;
    }
    // @include small-desktop() {
    //   margin-left: 3rem;
    // }
    // @include desktop() {
    //   margin-left: 5rem;
    // }
    .prod-heading {
      span.pr_sub_heading {
        font-size: 1.6em;
        color: $primary-color;
        font-weight: 600;
        display: block;
        margin-bottom: 1rem;
      }
      h2 {
        font-size: 2.4rem;
        font-weight: 600;
      }
      p {
        margin-top: 1rem;
        color: $dark-grey;
        font-size: 2rem;
        @include small-tab() {
          font-size: 1.8rem;
        }
      }
    }
    .prod-features {
      font-size: 1.6rem;
      margin-top: 3rem;
      @include small-tab() {
        margin-top: 2rem;
      }
      h4 {
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        @include small-tab() {
          font-size: 2rem;
        }
        // span {
        //   color: $primary-color;
        // }
        img {
          margin-right: 0.8rem;
          margin-top: 0.2rem;
          width: 1rem;
          height: 1rem;
          transform: rotate(45deg);
          display: none;
        }
      }
      .key {
        color: $secondary-color;
        padding-right: 0.5rem;
        font-weight: 500;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background-color: $secondary-color;
          height: 5px;
          width: 5px;
          z-index: 1;
          border-radius: 50%;
          top: 50%;
          left: -0.5rem;
          transform: translate(-0.5rem, -50%);
        }
      }
      ul,
      ol {
        margin-top: 1rem;
        margin-left: 1rem;
        li {
          margin-bottom: 0.5rem;
          font-size: 2rem;
          @include small-tab() {
            font-size: 1.6rem;
          }
          font-weight: 400;
          position: relative;
          margin-right: 1rem;
          &::marker {
            color: $primary-color;
          }
        }
      }
      a {
        color: $primary-color;
      }
      &._dynamic {
        ul,
        ol {
          margin-left: 0;
        }
      }
    }
    .unit-quantity-wrap {
      margin-top: 2rem;
      @include small-tab() {
        margin-top: auto;
      }
      .input-wrap {
        display: flex;
        flex-direction: column;
        label {
          font-size: 1.6rem;
          font-weight: 600;
        }
        input,
        select {
          font-size: 1.6rem;
          height: 4rem;
          margin-top: 0.5rem;
          border: 1px solid $primary-color;
          border-radius: 0.4rem;
          min-width: 8rem;
        }
        input {
          margin-right: 1rem;
          text-align: center;
          &:focus {
            outline: none;
            border-color: $primary-color;
          }
        }
      }
      .prod-btn-wrap {
        display: flex;
        align-items: center;
        @include desktop() {
          flex-direction: column;
        }
        button {
          @include desktop() {
            width: 100%;
            justify-content: space-between;
          }
          span {
            width: 100%;
            @include mobile() {
              width: 80%;
            }
            @include big-tab() {
              font-size: 1.6rem;
            }
          }
          svg {
            display: none;
            @include mobile() {
              display: block;
            }
            @include desktop() {
              width: 14%;
            }
          }
        }
      }
      button.cart_btn {
        @include desktop() {
          width: 100%;
          justify-content: space-between;
          svg {
            @include desktop() {
              width: 14%;
            }
          }
        }
      }
    }
    ._share {
      position: relative;
      // margin-top: 1rem;
      ul {
        display: flex;
        align-items: center;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 0.3rem;
            svg {
              width: 2rem;
              height: 2rem;
              fill: $white-color;
            }
            &._facebook {
              background-color: #4267b2;
            }
            &._linkedin {
              background-color: #0072b1;
            }
            &._twitter {
              background-color: #000;
            }
            &._mail {
              background-color: #c71610;
            }
            &._wp {
              background-color: #25d366;
            }
            &._any {
              background-color: #0005;
            }
          }
        }
      }
    }
  }
}

#enquiryModal {
  background-color: rgba(0, 0, 0, 0.02);
  .modal-content {
    border-radius: 0.9rem;
    .modal-body {
      position: relative;
      .modal-close-btn {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 5px;
        padding: 10px;
        background: transparent;
        border: none;
        @include small-tab() {
          top: -28px;
          right: -28px;
          background-color: #fff;
          border-radius: 50%;
          padding: 5px;
          border: none;
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      @include small-tab() {
        padding: 2.8rem;
      }
      box-shadow: 0 2px 40px rgb(207 217 233/30%);
      filter: drop-shadow(5px 5px 10px rgba(4, 193, 218, 0.0745098039));
    }
    .form-wrap {
      // background-color: #fff;

      margin: auto;
      border-radius: 15px;
      .heading {
        p {
          font-size: 1.6rem;
          margin-top: 9px;
        }
      }

      input,
      textarea,
      select {
        padding: 0.5rem 0.75rem !important;
        font-size: 1.5rem !important;

        &::placeholder {
          color: #ababab;
          font-size: 1.4rem !important;
          font-weight: 300;
          @include mobile() {
            font-size: 1.5rem !important;
          }
        }

        &:focus {
          border-color: $primary-color;
          box-shadow: none;
        }
      }
    }
  }
}
section.dashboard {
  background-color: #efefef;
}

section.cart {
  .heading {
    h2 {
      font-size: 3.5rem;
      text-align: center;
      flex-direction: column;
      @include mobile() {
        font-size: 4.5rem;
      }
    }
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @include mobile() {
      flex-direction: row;
      gap: 15px;
    }
  }
}

section.cart,
section.dashboard {
  background-color: #efefef;

  .cart-wrapper {
    margin: 2rem 0 3rem 0;
    border-radius: 12px;
    .total-line {
      font-size: 1.8rem;
      font-weight: 600;
    }
    .cart-heading {
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .cart-wrap-list {
      list-style-type: none;
      border-radius: 12px;
      background-color: $white-color;
      padding: 5px;
      @include mobile() {
        padding: 10px;
      }
      .update-btn {
        font-size: 1.6rem;
        border: 1px solid $secondary-color;
        outline: 0;
        background-color: transparent;
        width: 100%;
        margin-top: 5px;
        text-transform: uppercase;
        color: $white-color;
        padding: 0.4rem 2rem;
        border-radius: 4px;
        background-color: $secondary-color;
        &._hide {
          visibility: hidden;
        }
      }
      .cart-item {
        background-color: $white-color;
        display: flex;
        align-items: flex-start;

        justify-content: space-between;
        padding: 1rem;
        @include mobile() {
          padding: 2rem;
          align-items: center;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ededed;
        }

        figure {
          height: 80px;
          width: 80px;
          margin-bottom: 0;
          border: 1px solid #dcdcdc;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .item-info-list {
          padding-left: 1.5rem;
          @include mobile() {
            padding-left: 2rem;
          }
        }
        .quantity-wrap {
          h5 {
            font-size: 1.6rem;
          }

          .input-wrap {
            display: flex;
            flex-direction: column;
            label {
              font-size: 1.6rem;
              font-weight: 600;
            }
            input,
            select {
              font-size: 1.6rem;
              height: 4rem;
              margin-top: 0.5rem;
              border: 1px solid $primary-color;
              border-radius: 0.4rem;
              min-width: 4rem;
            }
            input {
              margin-right: 1rem;
              text-align: center;
              width: 7rem;
              &:focus {
                outline: none;
                border-color: $primary-color;
              }
            }
          }
          .update-btn {
            font-size: 1.4rem;
            border: 0;
            outline: 0;
            background-color: transparent;
            width: 100%;
            margin-top: 5px;
            text-transform: uppercase;
            color: $secondary-color;
            text-align: right;
            &._hide {
              visibility: hidden;
            }
          }
        }
        .cart-delete-btn {
          background-color: transparent;
          border-style: none;
          height: min-content;
          span {
            height: 20px;
            width: 20px;
            display: block;
            @include mobile() {
              height: 25px;
              width: 25px;
            }
          }
        }
        .item-info-list {
          list-style-type: none;
          li {
            p {
              color: $grey;
              margin-bottom: 0;
              font-size: 1.6rem;
            }
            &.bold {
              p {
                font-weight: 600;
                color: $font-color;
              }
            }
            &.big {
              p {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}

.auth-form-wrapper {
  background-color: $white-color;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 18px 1px rgba(121, 121, 121, 0.12);
  form {
    .auth-label {
      font-size: 1.8rem;
      font-weight: 600;
      color: $font-color;
      @include big-tab() {
        font-size: 1.6rem;
      }
    }
    a.auth-label {
      transition: 0.3s;
      color: $secondary-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
  input {
    padding: 1rem;
    font-size: 1.8rem;
    @include desktop() {
      font-size: 1.6rem;
    }
    &:focus {
      box-shadow: none;
      border-color: $secondary-color;
    }
  }
  .custom-btn {
    @include center();
  }
}

.page-link {
  color: $secondary-color;
  font-size: 1.4rem;
  &:hover {
    color: $secondary-color;
  }
  svg {
    padding-left: 5px;
    padding-right: 5px;
    path {
      color: $secondary-color;
    }
  }
  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
.mobile-menu {
  .offcanvas-header {
    padding: 20px;
    align-items: flex-start;
    .logo {
      .vivan-logo {
        width: 30rem;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .btn-close {
      background-size: 2.2rem;
      height: 2.2rem;
      width: 2.2rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .offcanvas-body {
    .nav-links-wrap {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      list-style-type: none;
      padding-left: 0;
      margin: 20px 0;

      li {
        color: $font-color;
        padding: 10px 20px;
        position: relative;
        @include small-tab() {
          padding: 14px 20px;
        }
        border-bottom: 1px solid #f3f3f3;
        width: 100%;

        button {
          background-color: transparent;
          border-style: none;
          color: $font-color;
        }
        .accordion-body {
          padding: 0;
          border-top: 1px solid #f3f3f3;
          margin-top: 12px;
          .nav-links-wrap {
            padding-left: 0;
            li {
              a {
                font-size: 1.8rem;
            
              }
              &:last-of-type {
                padding-bottom: 0;
                border-style: none;
              }
            }
          }
          .dropdown-list-menu {
            list-style-type: none;
            padding-left: 0;
            li {
              padding-left: 0;
              a{
                display: inline-block;
                height: 100%;
                width: 100%;
              }
              &:last-child {
                border-bottom: none;
                padding-bottom: 0;
              }
            }
          }
        }
        a {
          font-size: 2rem;
          color: $font-color;
          transition: 0.3s;
              
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.listed-content {
  .check-list-pointers {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      position: relative;
      padding-left: 3rem;
      margin-bottom: 1.6rem;
      &::before {
        content: "";
        background: url("/assets/images/common/check-mark.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 1.3rem;
        width: 1.3rem;
        display: block;
        left: 0;
        top: 0.6rem;
        position: absolute;
      }
      p {
        margin-bottom: 0;
        font-size: 1.8rem;
      }
    }
  }
}

.quote-text {
  font-size: 4rem;
  margin-bottom: 2rem;
  @include big-tab() {
    margin-bottom: 0;
  }
  @include desktop() {
    font-size: 5rem;
  }
}
.body-text {
  p {
    font-size: 2rem;

    @include desktop() {
      font-size: 1.8rem;
    }
  }
}

section.quote-cta {
  p {
    font-size: 2rem;
    line-height: 3.5rem;
  }
}

figure {
  &.custom-figure,
  &.custom-figure-big {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      &._contain{
        object-fit: contain;
      }
    }
  }
  &.custom-figure {
    height: auto;
    width: auto;
    @include big-tab() {
      margin-top: 3rem;
    }
    @include desktop() {
      height: 100%;
      width: 100%;
      margin-top: 0rem;
    }
  }
  &.custom-figure-big {
    height: auto;
    width: auto;
    @include big-tab() {
      height: 100%;
      width: 100%;
    }
  }
}

section.about-vial,
section.guiding-governance {
  background-color: #f3f3f3;
}

.value-wrapper {
  width: 100%;
  margin: auto;
  padding: 1rem;
  border-radius: 1rem;
  @include mobile() {
    padding: 2.5rem;
  }
  @include big-tab() {
    padding: 3rem;
  }

  margin-bottom: 5rem;
  background-color: $white-color;
  @include big-tab() {
    width: 80%;
  }
  figure {
    width: 8rem;
  }
}

section.logo-languages {
  .logo-desc-wrapper {
    margin-bottom: 3rem;
    @include mobile() {
      padding: 0 2rem;
    }
    span {
      font-size: 2rem;
      font-weight: 600;
    }
    h3 {
      font-size: 4rem;
      margin: 0 0 1rem 0;
      font-weight: 600;
    }
    p {
      font-size: 1.8rem;
    }
  }
}

.bottom-highlight-text {
  .highlight-text-wrapper {
    position: relative;
    padding-left: 4rem;
    &::before {
      content: "";
      height: 100%;
      width: 4px;
      background-color: $secondary-color;
      position: absolute;
      left: 0;
    }
    h4 {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      font-weight: 600;
    }
    p {
      font-size: 1.8rem;
    }
  }
}
.mission-list {
  .mission-list-item {
    display: flex;
    column-gap: 1.5rem;
    @include small-tab() {
      column-gap: 2.5rem;
    }
    align-items: center;
    .mission-check {
      svg {
        fill: $white-color;
      }
      background-color: $primary-color;
      border-radius: 50%;
      display: inline-block;
      height: 25px;
      width: 25px;
      padding: 5px;
      @include small-tab() {
        height: 50px;
        width: 50px;
        padding: 10px;
      }

      @include center();
    }
    p {
      position: relative;
      font-size: 1.8rem;
      margin-bottom: 0;
      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: #f4f4f4;
        position: absolute;
        bottom: -1rem;
        left: 0;
      }
    }
  }
}

section.fixed-bg-with-text {
  &.texture-bg {
    padding-top: 0;
    background: url("/assets/images/about-us/2-Specialized-Chemicals.jpg");
    @include background(center, fixed);

    &.no-bg {
      background: unset;
    }
  }
  .fixed-bg {
    width: 100%;
    height: 60vh;
    &.corporate {
      @include background(center, fixed);
      background: url("/assets/images/corporate-strategy/3-Corporate-Strategy-bg.jpg");
      background-attachment: fixed;
    }

    &.ipr {
      @include background(center, fixed);
      background: url("/assets/images/IPR-practices/3-IPR-practices-bg.jpg");
      background-attachment: fixed;
    }
  }
  .lifted-content {
    margin-top: -15rem;
    background-color: $white-color;
    padding: 5rem 2rem;
    @include mobile() {
      padding: 5rem 4rem;
    }
  }
}

section.business-philosophy,
section.ccrs-features {
  background-color: #efefef;
  padding: 0 0;
  .content-wrapper {
    padding: 3rem 0;
    margin: auto;
    @include mobile() {
      width: 540px;
    }
    @include small-tab() {
      width: 720px;
    }
    @include big-tab() {
      padding: 5rem 0 5rem 6rem;
      width: auto;
    }
    @include desktop() {
      padding: 8rem 0 8rem 15rem;
    }
  }
}

.my-dashboard {
  font-size: 1.6rem;
}

// Loader

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: #fff;
}

@keyframes ldio-3jrwy8iqvsw-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(100px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(100px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-3jrwy8iqvsw {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ldio-3jrwy8iqvsw div {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: 75px;
  left: 25px;
}
.ldio-3jrwy8iqvsw div:nth-child(1) {
  background: #f3ad1b;
  animation: ldio-3jrwy8iqvsw 2s linear infinite;
  animation-delay: -1s;
}
.ldio-3jrwy8iqvsw div:nth-child(2) {
  background: #a32b23;
  animation: ldio-3jrwy8iqvsw 2s linear infinite;
  animation-delay: 0s;
}
.ldio-3jrwy8iqvsw div:nth-child(3) {
  background: #f3ad1b;
  animation: ldio-3jrwy8iqvsw-o 2s linear infinite;
  animation-delay: -1s;
}
.loadingio-spinner-dual-ball-lepv36wr7t {
  width: 250px;
  height: 250px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.ldio-3jrwy8iqvsw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  background-color: #fff;
}
.ldio-3jrwy8iqvsw div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
section.about-vivan-acl,
section.about-vil,
section.contact-us,
section.guided-by-integrity,
.pattern-bg {
  background: url("/assets/images/about-us/2-Specialized-Chemicals.jpg");
  @include background(center, fixed);
  background-size: cover;
}
section.vivan-text,
section.logo-languages {
  background-color: #ffeff0;
}
section.vivan-text {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 7rem 0 5rem;
  &::after {
    content: "";
    height: 1px;
    width: 80%;
    background-color: $secondary-color;
    position: absolute;
    bottom: 0;
  }
}

.vivan-heading-logo {
  .logo-image-wrapper {
    padding: 2rem;
    @include mobile() {
      padding: 8rem;
    }
    background-color: #c54a3b;
    border-radius: 50%;
    width: min-content;
    margin: auto;
    aspect-ratio: 1/1;
    @include center();
  }
  .custom-figure {
    // height: 200px;
    width: 260px;
    @include mid-small-mobile() {
      width: 320px;
    }
    @include mobile() {
      width: 400px;
    }

    height: auto;
    margin: auto;
    img {
      object-fit: contain;
      height: min-content;
    }
  }
}

section.vivan-logo {
  background: linear-gradient(45deg, #525c61, #969b9d);
  .logo-image-wrapper {
    padding: 6rem;
    @include mobile() {
      padding: 8rem;
    }
    background-color: #3d484c;
    border-radius: 50%;
    width: min-content;
    margin: auto;
    aspect-ratio: 1/1;
    @include center();
  }
  .custom-figure {
    // height: 200px;
    width: 160px;
    @include mid-small-mobile() {
      width: 220px;
    }
    @include mobile() {
      width: 300px;
    }

    height: auto;
    padding: 3rem;
    margin: auto;
    img {
      object-fit: contain;
      height: min-content;
    }
  }
}
section.logo-colors {
  figure {
    width: 50%;
    margin-right: auto;
    @include mobile() {
      margin: auto;
    }
    @include big-tab() {
      width: auto;
    }
  }
  .small-heading {
    &.primary {
      color: $primary-color;
    }
    &.secondary {
      color: $secondary-color;
    }
  }
}
section.values-list {
  background: url("/assets/images/Values/2-our-values-bg.jpg");
  @include background(center, fixed);
}

section.quote {
  &.vision {
    background: url("/assets/images/Vision/2-quote-bg.jpg");
    @include background(center, fixed);
  }
}

section.quote-cta {
  &.mission {
    background: url("/assets/images/Mission/4-mission-bg.jpg");
    @include background(center, fixed);
  }
  &.vision {
    background: url("/assets/images/Vision/4-Specialized-Chemicals.jpg");
    @include background(center, fixed);
  }
}
section.about-mission {
  background: url("/assets/images/Mission/2-mission-bg.jpg");
  @include background(center, fixed);
}
.business-philosophy-bg {
  background: url("/assets/images/corporate-strategy/4-VIVANACLs-Business-Philosophy.jpg");
  @include background(center, scroll);
  height: 50vh;
  width: 100%;
  @include big-tab() {
    height: 100%;
    width: 100%;
  }
}

.ccrs-features-bg {
  background: url("/assets/images/cCRS/3-cCRS-page-side.jpg");
  @include background(center, scroll);
  height: 50vh;
  width: 100%;
  @include big-tab() {
    height: 100%;
    width: 100%;
  }
}

section.contact-us,
section.feedback-form {
  .contact-us-wrapper {
    background-color: $white-color;
    border-radius: 12px;
    margin-top: 2rem;
    padding: 1rem;
    &.small {
      padding: 2.5rem;
    }
  }
  .contact-us-form-wrapper {
    padding: 3rem 0;
    label {
      font-size: 1.8rem;
      font-weight: 600;
      color: $font-color;
      margin-bottom: 0.1rem;
    }
    input,
    textarea {
      padding: 1rem 1.5rem 1rem 0;
      font-size: 1.8rem;
      border-radius: 0;
      &::placeholder {
        font-size: 1.6rem;
      }
      &:focus {
        box-shadow: none;
        border-color: $secondary-color;
        & + label {
          color: $secondary-color !important;
        }
      }

      border-style: unset;
      border-bottom: 1px solid #dddde6;
    }
  }
  .contact-us-content-wrapper {
    // background: url("/assets/images/Vision/2-quote-bg.jpg");
    height: 100%;
    background-color: $grey;
    color: $white-color;
    @include background(center, scroll);
    padding: 3rem 1.5rem;
    @include mobile() {
      padding: 2.6rem 3.2rem;
    }
    border-radius: 10px;
    h3 {
      color: $white-color;
    }
    p {
      font-size: 1.7rem;
      color: $white-color;
    }
    h2 {
      font-size: 2rem;
      font-weight: 600;
    }
    .icon-wrapper-text {
      font-size: 1.8rem;
      color: $white-color;
      display: flex;
      align-items: flex-start;
      column-gap: 1rem;
      margin-bottom: 1.4rem;
      span.icon {
        height: 2rem;
        width: 2rem;
        display: inline-block;
        svg {
          height: 2rem;
          width: 2rem;
        }
      }
      a,
      span,
      p {
        color: $white-color;
        font-weight: 400;
        transition: 0.3s;
        // &:hover {
        //   color: $secondary-color;
        // }
      }
    }
  }
}

#enquiry_form {
  input,
  textarea {
    font-size: 1.6rem;
    height: 4rem;
    margin-top: 0.5rem;
    border: 1px solid #f3ad1b;
    border-radius: 0.4rem;
    min-width: 4rem;
  }
  textarea {
    height: 8rem;
  }
}

.session-alert {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
section.faq {
  ._blob {
    z-index: 0;
    &.purple {
      left: 0;
      bottom: 25%;
    }
  }
  .big-heading {
    @include center();
    flex-direction: column;
    margin-bottom: 40px;
    row-gap: 15px;
    h2 {
      font-size: 30px;
      @include mobile() {
        font-size: 40px;
      }
      @include big-tab() {
        font-size: 60px;
      }
      text-align: center;
      font-weight: 600;
    }
  }
  .heading-badge {
    color: $primary-color;
    background-color: #fae6df;
    font-size: 12px;
    letter-spacing: 0px;
    border-radius: 10px;
    font-weight: 600;
    padding: 4px 10px;
  }
  .accordions-wrap {
    padding: 30px 20px;
    position: relative;
    z-index: 1;
    @include small-tab() {
      padding: 40px 50px;
    }

    background-color: $white-color;
    box-shadow: 3px 2px 59px -12px rgba(218, 218, 218, 0.74);
    border-radius: 12px;
    list-style-type: none;
    li {
      .accordion-header {
        display: flex;

        padding: 24px 0 0 0;

        cursor: pointer;
        transition: 0.4s all ease-in-out;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 30px;
          right: 10px;
          transform: rotate(45deg);
          height: 10px;
          width: 10px;
          clip-path: polygon(
            20% 0%,
            0% 20%,
            30% 50%,
            0% 80%,
            20% 100%,
            50% 70%,
            80% 100%,
            100% 80%,
            70% 50%,
            100% 20%,
            80% 0%,
            50% 30%
          );
          background-color: black;
          transition: all 0.4s ease;
        }
        p {
          transition: 0.3s all ease-in-out;
          font-size: 16px;
          margin-right: 25px;
          letter-spacing: normal;
          @include small-tab() {
            font-size: 20px;
          }

          font-weight: 700;
          margin-bottom: 0;
        }
      }
      .accordion-content {
        color: $font-color;
        background-color: transparent;
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        padding-top: 24px;
        p {
          color: #8f8f8f;
          letter-spacing: normal;
          font-size: 18px;
        }
      }
      &.active {
        .accordion-content {
          max-height: 500px;
          padding-bottom: 20px;
        }
        .accordion-header {
          p {
            color: $primary-color;
          }
          &::after {
            rotate: 180deg;

            background-color: $primary-color;
            clip-path: polygon(30% 50%, 0% 80%, 20% 100%, 50% 70%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
          }
        }
      }
      &:not(:last-child) {
        .accordion-content {
          border-bottom: 1px solid #eeeeee;
        }
      }
    }
  }
}

.job-cards-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 1rem;
  li {
    width: 100%;
    margin: auto;
    display: flex;
    background-color: $white-color;
    border-radius: 12px;
    border: 2px solid transparent;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 2.5rem;
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0rem;
    }

    @include small-tab() {
      width: 48%;
      // height: 100%;
      transition: 0.3s all ease-in-out;
      &:last-child {
        margin-bottom: 2rem;
      }
    }
    @include mobile() {
      &:hover {
        border-color: $primary-color;
        transform: translateY(-10px);
      }
    }
    .text-wrap {
      h4 {
        font-size: 2rem;
        @include big-tab() {
          font-size: 2.4rem;
        }
        font-weight: 600;
      }
      p {
        font-size: 1.6rem;
      }
    }
    .arrow-svg {
      height: 18px;
      @include big-tab() {
        height: 25px;
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.products-list {
  ul {
    padding-left: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem;
      column-gap: 1rem;
      height: 100%;
      @include small-tab() {
        padding: 1.5rem;
      }
      @include big-tab() {
        padding: 2rem;
      }
      &:hover {
        transform: none;
        border-color: transparent;
      }
      p {
        font-size: 1.6rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: $grey;
      }
      .text-wrap {
        h4 {
          font-size: 2rem;
          margin-bottom: 0;
        }
      }
      figure {
        height: 80px;
        width: 80px;
        aspect-ratio: 1/1;
        margin-bottom: 0;
        border: 1px solid #e7e7e7;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .quantity-wrap {
        p {
          margin-bottom: 0;
          color: $secondary-color;
        }
      }
    }
  }
}

.cookie-card {
  position: fixed;
  bottom: 2rem;
  left: 1rem;
  background-color: $white-color;
  width: 95%;
  @include small-mobile() {
    width: 40rem;
    left: 2rem;
  }
  border-radius: 1.5rem;
  z-index: 5;
  .card {
    padding: 1.5rem;
    border-radius: 0.6rem;
    border: 1px solid rgba($color: $primary-color, $alpha: 0.3);
    box-shadow: 0 0 22px 5px #a32b232e;
    h2 {
      font-weight: 600;
      color: $secondary-color;
    }
    .btn-link {
      font-size: 19px;
    }
    .act-btn {
      font-size: 1.6rem;
      border: 1px solid $secondary-color;
      padding: 6px 18px;
      color: $secondary-color;
      background-color: $white-color;
      &:first-child {
        margin-right: 1rem;
        background-color: $secondary-color;
        color: $white-color;
      }
    }
  }
}

.not_found {
  margin-top: 2rem;
  height: 20rem;
  background-color: #fff;
  border-radius: 1.5rem;
  border: 1px solid rgba($color: $primary-color, $alpha: 0.2);
  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: #778a92;
    }
  }
}

.thank-you-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: $white-color;
  justify-content: space-between;
  figure {
    border-radius: 0.4rem;
    overflow: hidden;
    width: 10rem;
    margin-right: 1.5rem;
    img {
      width: 100%;
    }
  }
  .text-wrap {
    h4 {
      font-size: 2rem;
      font-weight: 600;
    }
  }
}
.enquiries-wrapper {
  row-gap: 1.5rem;
  display: flex;
  flex-direction: column;
  .enquiry-item {
    background-color: $white-color;
    padding: 2rem;
    border-radius: 1rem;
    .enquiry-title {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    figure {
      width: 9rem;
      margin-bottom: 0;
      padding: 2px;
      aspect-ratio: 1/1;
      border: 1px solid #e9e9e9;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .enquiry-products-list {
      list-style-type: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .enquiry-info-wrap {
      h4 {
        font-weight: 700;
        font-size: 1.8rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      p {
        font-size: 1.6rem;
        @include mobile() {
          font-size: 1.8rem;
        }
      }
    }
    .quantity-wrap {
      h5 {
        font-size: 1.8rem;
        @include big-tab() {
          font-size: 1.6rem;
        }
      }
      .quantity-box {
        border: 1px solid $primary-color;
        display: flex;
        padding: 0.4rem 1.2rem;
        border-radius: 1rem;
        p {
          font-size: 1.6rem;
          @include mobile() {
            font-size: 1.8rem;
          }
        }
      }
    }
    .enquiry-footer {
      display: flex;
      padding-top: 2rem;
      margin-top: 3rem;
      justify-content: space-between;
      border-top: 1px solid #e9e9e9;
    }
  }
}

.cta-slider-2 {
  .splide__arrow {
    height: 4rem;
    width: 4rem;
  }
}

  .certificate-card {
    position: relative;
    height: 300px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s;
    figure {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .hover-overlay {
      background-color: transparent;
      position: absolute;
      height: 100%;
      border-radius: 10px;
      width: 100%;
      left: 0;
      top: 0;
      transition: 0.3s;
      @include center();
      flex-direction: column;
      gap: 15px;
      span {
        @include center();
        height: 50px;
        width: 50px;
        transform: translateY(-10px);
        opacity: 0;
        transition: 0.3s;
        background-color: rgba($white-color, 0.3);
        border-radius: 50%;
        padding: 14px;
        svg {
          height: 100%;
          width: 100%;
          fill: $white-color;
        }
      }
      p {
        background-color: rgba($white-color, 0.3);
        color: $white-color;
        margin-bottom: 0;
        transform: translateY(10px);
        opacity: 0;
        transition: 0.3s;
        padding: 3px 10px;
        border-radius: 6px;
      }
    }

    &:hover {
      .hover-overlay {
        background-color: rgba($font-color, 0.5);
        span,
        p {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

section.certificates {
  background-color: #efefef;
}

.fancybox-button {
  svg {
    path {
      fill: $white-color !important;
    }
  }
}
.footer-img-link{
  figure{
    width: 70%;
    max-width: 150px;
    img{
      height: 100%;
      width: 100%;
    }
  }
}
