// variables
$white-color: #fff;
$primary-color: #eda812;
$primary-color-dark: #d69f2a;
$primary-color-extra-dark: #473000;
$secondary-color: #A32B23;
$font-color: #232323;
$grey: #64757c;
$light-grey: #e0f3fc;
$dark-grey: #32393c;

// breakpoints
$too-small-mobile: 322px;
$mid-small-mobile:340px;
$small-mobile: 376px;
$mid-mobile:500px;
$mobile: 576px;
$small-tab: 768px;
$big-tab: 992px;
$small-desktop: 1024px;
$desktop: 1200px;
$mid-desktop: 1450px;
$large-desktop: 1700px;
